<template>
  <div style="margin-top: 15px">
    <lineTitle title="我的CDK" />
    <el-tabs v-model="curTab" @tab-click="handleTab" class="tab_list" id="tabs">
      <el-tab-pane v-for="item in tabs" :key="item.tab" :name="item.tab">
        <span slot="label"><i :class="item.icon"></i> {{ item.name }}</span>
      </el-tab-pane>
    </el-tabs>
    <div class="revenue_records" v-if="curTab == 1">
      <el-table rowKey="id" :data="hongbaoList" class="table_list" style="width: 100%" header-row-class-name="table_header" row-class-name="table_row" cell-class-name="table_cell" v-loading="hongbaoLoading">
        <!-- <el-table-column prop="id" label="id" min-width="60" /> -->
        <el-table-column prop="password" label="CDK" :min-width="isPc ? '180' : '270'" align="center">
          <template slot-scope="scope">
            <div class="box">
              <div class="CDK" style="user-select: text">
                {{ scope.row.password }}
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="title" label="名称" min-width="60" /> -->
        <el-table-column prop="minMoney" label="价值" min-width="50" />
        <el-table-column prop="amount" label="领取状态" min-width="70">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0" style="color: red">未领取</div>
            <div v-else :style="{ color: $main }">已领取</div>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="150" />
        <div slot="empty">--暂无CDK信息--</div>
      </el-table>
      <el-pagination background layout="prev, pager, next" :hide-on-single-page="true" :total="hongbaoListTotal" :current-page="hongbaoListPage.pageNum" :page-size="hongbaoListPage.pageSize" @current-change="queryHongbaoList" />
    </div>
    <div class="revenue_records" v-if="curTab == 3">
      <el-table rowKey="id" :data="redPacketRecordList" class="table_list" style="width: 100%" header-row-class-name="table_header" row-class-name="table_row" cell-class-name="table_cell" v-loading="redPacketRecordLoading">
        <!-- <el-table-column prop="redPacketId" label="id" min-width="60" /> -->
        <el-table-column prop="receivePassword" label="CDK" :min-width="isPc ? '180' : '270'" align="center">
          <template slot-scope="scope">
            <div class="box">
              <div class="CDK" style="user-select: text">
                {{ scope.row.receivePassword }}
              </div>
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="title" label="CDK名称" min-width="60" /> -->
        <el-table-column prop="nickName" label="领取者" min-width="70" />
        <!-- <el-table-column prop="userId" label="领取者id" min-width="60" /> -->
        <el-table-column prop="receiveAmount" label="价值" min-width="50" />

        <el-table-column prop="receiveTime" label="领取时间" min-width="150" />
        <div slot="empty">--暂无领取信息--</div>
      </el-table>
      <el-pagination background layout="prev, pager, next" :hide-on-single-page="true" :total="redPacketRecordTotal" :current-page="redPacketRecordPage.page" :page-size="redPacketRecordPage.size" @current-change="queryRedPacketRecord" />
    </div>
    <div class="new_box" v-if="curTab == 2">
      <!-- <div class="name">
        <h2><span>1</span>CDK名称</h2>
        <input type="text" v-model="newHongbao.title" maxlength="20" placeholder="请输入您的CDK名称" />
      </div> -->
      <div class="name">
        <h2><span>1</span>CDK价值</h2>
        <input type="number" ref="input" v-model="newHongbao.minMoney" @keyup="change($event, 'minMoney')" maxlength="20" placeholder="请输入您的CDK价值" />
      </div>
      <div class="name">
        <h2><span>2</span>CDK个数</h2>
        <input type="number" v-model="newHongbao.createNum" maxlength="20" placeholder="请输入要创建的CDK数量" @keyup="change($event, 'createNum')" />
      </div>

      <div class="summary">
        <p>
          CDK花费：<span>{{ newHongbao.minMoney * newHongbao.createNum || 0 }}</span>
        </p>
        <button @click="handleCreate" :class="{ opacity: isOpacity }">创建CDK</button>
      </div>
      <div class="tip_content">
        <p>*CDK规则：创建CDK时优先消费金币，后消费积分。</p>
        <p>*CDK价值：CDK最小面额为1,且只能输入整数。</p>
        <p>*领取规则：每个CDK只能使用一次，任何用户都可领取</p>
      </div>
    </div>
  </div>
</template>

<script>
// createRedPacket
import { createRedPacket, getUserInfo, redPacketList, redPacketRecord } from '@/api/index';
import lineTitle from '@/components/lineTitle';
export default {
  components: { lineTitle },
  data() {
    return {
      tabs: [
        { name: 'CDK列表', icon: 'el-icon-s-home', tab: '1' },
        { name: 'CDK领取记录', icon: 'el-icon-s-custom', tab: '3' },
        { name: '创建CDK', icon: 'el-icon-s-platform', tab: '2' },
      ],
      revenueListPage: [],
      curTab: '2',

      newHongbao: {
        // title: '',
        minMoney: '',
        createNum: '',
        num: 1,
      },
      hongbaoLoading: false,
      hongbaoList: [],
      hongbaoListTotal: 0,
      hongbaoListPage: {
        pageNum: 1,
        pageSize: 10,
      },
      redPacketRecordLoading: false,
      redPacketRecordPage: {
        pageNum: 1,
        pageSize: 10,
      },
      redPacketRecordList: [],
      redPacketRecordTotal: 0,
    };
  },
  mounted() {
    // this.queryHongbaoList(1)
    // this.queryRedPacketRecord(1)
  },
  methods: {
    change(e, t) {
      // console.log('变化',e.target.value);
      this.newHongbao[t] = e.target.value.replace(/^(0+)|[^\d]+/g, '');
    },
    validateNumber(event) {
      const val = event.target.value.trim();
      if (!val) return;

      const value = val
        // .replace(/(\.\d)\d*/, '$1') //保留两位小数
        .replace(/(\.\d{1,2})\d*/, '$1') //保留两位小数
        .replace(/[^\d.]/g, '') // 清除"数字和."以外的字符
        .replace(/^\./g, '') // 验证第一个字符是数字
        .replace(/^0+\./g, '0.') //把小数0开头的多余0去掉
        .replace(/^[0]+/, ''); //把整数0开头的多余0去掉
      const result = parseFloat(value); //去除末尾的0
      // this.resultList[index].oddsResult = result;
      this.$refs.input.value = result;
    },
    queryHongbaoList(page) {
      this.hongbaoLoading = true;
      if (page) this.hongbaoListPage.pageNum = page;
      redPacketList(this.hongbaoListPage).then(res => {
        this.hongbaoLoading = false;
        if (res.data.code == 200) {
          this.hongbaoList = [...res.data.rows];
          this.hongbaoListTotal = res.data.total;
        }
      });
    },
    queryRedPacketRecord(page) {
      this.redPacketRecordLoading = true;
      if (page) this.redPacketRecordPage.pageNum = page;
      redPacketRecord(this.redPacketRecordPage).then(res => {
        this.redPacketRecordLoading = false;
        if (res.data.code == 200) {
          this.redPacketRecordList = [...res.data.rows];
          this.redPacketRecordTotal = res.data.total;
        }
      });
    },
    playAudio() {
      this.$store.commit('playAudio');
    },
    handleTab({ name }) {
      this.playAudio();
      // this.$router.replace(`/CDK?tab=${name}`);
      this.curTab = name;
      // if (this.curTab == 1) {
      //   this.queryHongbaoList(1);
      // } else if (this.curTab == 3) {
      //   this.queryRedPacketRecord(1);
      // }
    },
    handleCreate() {
      if (!this.isOpacity) return;
      let num = this.newHongbao.minMoney * this.newHongbao.createNum + '';
      if (num.split('.').length > 1) {
        this.$message({
          offset: 50,
          message: '请输入合法的数据',
          type: 'warning',
        });
        return;
      }
      createRedPacket(this.newHongbao).then(res => {
        console.log('创建CDK', res);
        if (res.data.code == 200) {
          // this.list = [res.data.data];
          this.$message({
            offset: 50,
            message: '创建成功',
            type: 'success',
          });
          getUserInfo().then(res => {
            this.$store.commit('USER_INFO', res?.data?.data || {});
          });
          // this.handleTab({name:1})
          // this.$router.replace(`/CDK?tab=1`);
          this.curTab = '1';
          this.newHongbao = {
            // title: '',
            minMoney: '',
            createNum: '',
            num: 1,
          };
        } else {
          this.$message({
            offset: 50,
            message: res.data.msg,
            type: 'warning',
          });
        }
      });
    },
  },
  computed: {
    isPc() {
      return this.$store.state.mode == 'pc';
    },
    isOpacity() {
      // if (this.newHongbao.title == '') return false;
      if (this.newHongbao.minMoney == '') return false;
      if (this.newHongbao.createNum == '' || this.newHongbao.createNum == 0) return false;
      return true;
    },
  },
  filters: {},
  watch: {
    curTab(val) {
      if (val == 1) {
        this.queryHongbaoList(1);
      } else if (val == 3) {
        this.queryRedPacketRecord(1);
      }
    },
    // '$route.query': {
    //   handler(val) {
    //     const tab = val?.tab || '1';
    //     this.curTab = tab;
    //     if(this.curTab==1){
    //       this.queryHongbaoList(1)
    //     }else if(this.curTab==3){
    //       this.queryRedPacketRecord(1)
    //     }
    //   },
    //   immediate: true,
    // },
  },
};
</script>

<style lang="less" scoped>
.opacity {
  opacity: 1;
}
.revenue_records {
  width: 69%;
  @media @max750 {
    width: 90%;
    margin: 10px auto 0;
  }
  margin: 70px auto 0;
  .flex-column;
  h2 {
    width: 160px;
    .sc(20px, #DADADA);
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #fff;
  }
}
.new_box {
  margin: 40px @pcSpace 0;
  @media @max750 {
    margin: 12px;
  }
  & > div {
    padding: 14px;
    background: #0d1214;
    border-radius: 10px;
    margin-bottom: 10px;
    &.name {
      .flex-a-center;
      white-space: nowrap;
      justify-content: space-between;
      input {
        flex: 1;
        height: 44px;
        margin-left: 30%;
        .sc(18px, #fff);
        background: @bg2;
        padding: 0 10px;
        border-radius: 4px;
        display: block;
      }
      @media @max750 {
        .flex-column;
        align-items: flex-start;
        input {
          flex: auto;
          width: 100%;
          height: 38px;
          margin-left: 0;
          font-size: 14px;
          margin-top: 10px;
          box-sizing: border-box;
        }
      }
    }
    &.select {
      .title {
        .flex-a-center;
        justify-content: space-between;
        button {
          .sc(18px, #fff);
          .btn-active(140px, 36px);
          border-radius: 20px;
          @media @max750 {
            font-size: 14px;
            .btn-active(100px, 30px);
          }
        }
      }
      .select_list {
        @media @max750 {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .item {
          margin-top: 20px;
          background: @bg1;
          padding: 4px 40px;
          border-radius: 10px;
          position: relative;
          .flex-a-center;
          @media @max750 {
            width: 48%;
            margin-top: 14px;
            padding: 10px 14px;
            box-sizing: border-box;
            .flex-column-center;
          }
          .img {
            .flex-center;
            .wh(100px, 100px);
            overflow: hidden;
            img {
              .wh(100%, auto);
            }
          }
          & > p {
            .ell;
            .sc(20px, #fff);
            flex: 2;
            margin: 0 40px;
            @media @max750 {
              margin: 0;
              flex: none;
              width: 100%;
              font-size: 14px;
              text-align: center;
            }
          }
          .price,
          .odds_input {
            flex: 1;
            height: 44px;
            background: @bg2;
            padding: 0 10px;
            margin-right: 20px;
            border-radius: 4px;
            .sc(18px, #fff);
            @media @max750 {
              height: 38px;
              margin-top: 4px;
              margin-right: 0;
              font-size: 14px;
            }
          }
          .odds_input {
            width: 100%;
            .flex-a-center;
            @media @max750 {
              padding: 0 6px;
            }
            input {
              width: 90%;
              height: 100%;
              display: block;
              background: none;
              .sc(16px, #fff);
              @media @max750 {
                height: 38px;
                font-size: 14px;
              }
            }
            .odds_text {
              padding-left: 10px;
            }
          }
          .icon {
            font-size: 26px;
          }
          @media @max750 {
            .price {
              background: none;
            }
            & > input {
              flex: none;
              width: 86%;
            }
            .icon {
              top: 6px;
              right: 6px;
              font-size: 20px;
              position: absolute;
            }
          }
        }
      }
    }
    &.summary {
      // .flex-a-center;
      .flex-a-between;
      @media @max750 {
        flex-wrap: wrap;
        justify-content: space-between;
      }
      & > p {
        width: 15%;
        margin-right: 20px;
        .sc(20px, #fff);
        span {
          color: @main;
        }
        @media @max750 {
          width: 40%;
          // margin: 0 auto;
          font-size: 14px;
          text-align: center;
        }
      }
      & > button {
        .sc(18px, #fff);
        .wh(160px, 50px);
        margin-left: auto;
        font-weight: bold;
        background: url(../../assets/image/loginbtn.png) no-repeat;
        background-size: 100% 100%;
        opacity: 0.5;
        @media @max750 {
          .wh(140px, 44px);
          margin-left: 0;
          margin-top: 10px;
          font-size: 16px;
        }
      }
      .opacity {
        opacity: 1;
      }
    }
    &.tip_content {
      & > p {
        .sc(18px, #fff);
        line-height: 26px;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        @media @max750 {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  h2 {
    .flex-a-center;
    .sc(20px, #fff);
    @media @max750 {
      font-size: 16px;
    }
    & > span {
      .wh(30px, 30px);
      .sc(18px, #fff);
      line-height: 30px;
      text-align: center;
      display: inline-block;
      border-radius: 50%;
      margin-right: 10px;
      background: rgba(@main, 0.7);
      @media @max750 {
        .wh(20px, 20px);
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  .type_list {
    margin-top: 20px;
    .flex-a-center;
    @media @max750 {
      margin-top: 10px;
    }
    .type_item {
      flex: 1;
      margin: 0 10px;
      position: relative;
      .flex-center;
      @media @max750 {
        margin: 0 2px;
      }
      img {
        .wh(100%, 100%);
        border-radius: 14px;
        transition: all 0.2s;
        border: 2px solid transparent;
        @media @max750 {
          border-radius: 10px;
        }
      }
      .checked {
        border-color: @main;
      }
    }
  }
}
</style>
